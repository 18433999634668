import * as React from 'react';

function Logo(props) {
  return (
    <svg
      width={50}
      height={47}
      viewBox="0 0 50 47"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 23.28C0 10.553 11.255.154 25 .154S50 10.553 50 23.28c0 12.727-11.255 23.126-25 23.126S0 35.976 0 23.28zm45.555 0c0-11.175-8.04-20.208-20.555-20.208-12.547 0-20.586 9.033-20.586 20.208 0 11.175 8.039 20.208 20.554 20.208 12.548 0 20.587-9.033 20.587-20.208z" />
      <path d="M18.001 17.258h8.008c2.616 0 4.287.248 5.548 1.242.662.527 1.104 1.396 1.104 2.266 0 .869-.442 1.552-1.23 2.173-.725.527-2.333.993-3.247.993l4.666 5.37h-3.783l-4.445-5.184h-3.09v5.184h-3.53V17.258zm8.197 5.246c1.734 0 2.9-.652 2.9-1.77 0-1.52-1.481-1.83-3.247-1.83h-4.319v3.6h4.666z" />
      <path d="M36.412 26.601c-1.135 5.215-5.17 9.033-11.412 9.033-7.472 0-11.854-5.37-11.854-12.137 0-6.798 4.35-12.168 11.854-12.168 6.053 0 10.057 3.57 11.318 8.598h4.413C39.061 13.41 32.598 8.504 25 8.504c-8.922.031-16.173 6.643-16.173 14.776S16.078 38.025 25 38.025c7.63 0 14.06-4.874 15.731-11.424h-4.319z" />
    </svg>
  );
}

export default Logo;
