import React from 'react';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';

/**
 *
 */
import useFooterData from '../../hooks/use-footer-data';
import Tick from '../tick';
import LinkExternal from '../link-external';
import Arrow from '../svg/arrow';
import { Button } from './button';

/**
 * Footer component
 *
 * @param {Object} props
 * @param {Object} props.pageContext
 */
export default function Footer({ pageContext }) {
  const {
    awards,
    contact,
    clients,
    experience,
    legal,
    talks,
    social_links,
    ticker_text
  } = useFooterData();

  if (pageContext.uid === 'playground') return <></>;

  return (
    <FooterContainer id="footer">
      {pageContext.uid === 'profile' && (
        <>
          <FootTick>
            <Tick tickerSpeed={5}>
              {() => <h3 className="sequel">{ticker_text}</h3>}
            </Tick>
          </FootTick>
          <FooterRow className="pV">
            {experience && (
              <ul>
                <h6>Experience</h6>
                <li>
                  <RichText render={experience} />
                </li>
              </ul>
            )}
            {clients && (
              <ul>
                <h6>Select Clients</h6>
                <li>
                  <p>{clients[0].text}</p>
                </li>
              </ul>
            )}
            {talks && (
              <ul>
                <h6>Talks + Interviews</h6>
                <li>
                  <RichText render={talks} />
                </li>
              </ul>
            )}
            {awards && (
              <ul>
                <h6>Awards</h6>
                <li>
                  <RichText render={awards} />
                </li>
              </ul>
            )}
            {contact && (
              <ul>
                <h6>Contact</h6>
                <li>
                  <Button
                    href={`mailto:${contact}`}
                    text="Email Me"
                    icon={<Arrow />}
                  />
                </li>
              </ul>
            )}
          </FooterRow>
        </>
      )}
      <FooterRow className="links">
        <h6>
          {legal} Site by{' '}
          <LinkExternal href="https://john.design" hoverData="john">
            John Choura
          </LinkExternal>
          .
        </h6>
        <FooterSocialIcons>
          {social_links.map((item, i) => (
            <LinkExternal
              key={i}
              href={item.url}
              className="no-underline"
              blank={true}
            >
              <img
                src={item.svg.url}
                width={item.svg.width}
                height={item.svg.height}
                alt={item.url}
              />
            </LinkExternal>
          ))}
        </FooterSocialIcons>
      </FooterRow>
    </FooterContainer>
  );
}

const FootTick = styled.div`
  margin: 2.5rem 0 4rem;

  @media ${(props) => props.theme.device.tablet} {
    margin: 6vw 0;
  }

  h3 {
    margin-right: .3em;
  }
`;

const FooterSocialIcons = styled.div`
  display: flex;
  width: 7.5rem;
  justify-content: space-between;
  mix-blend-mode: difference;
`;

const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 2vw;
  margin: 0 1rem;

  @media ${(props) => props.theme.device.tablet} {
    padding: 0 3vw 0;
  }

  &.pV {
    padding-top: 3vw;
    padding-bottom: 3vw;
  }

  &.links {
    flex-direction: column;

    @media ${(props) => props.theme.device.laptop} {
      flex-direction: row;
    }

    h6 {
      margin: 2rem 0 2rem;
      font-size: 0.65rem;
      line-height: 120%;
      max-width: 30em;

      @media ${(props) => props.theme.device.tablet} {
        margin: 0 0 2rem;
        font-size: 0.8rem;
        max-width: 50em;
      }
    }
  }

  ul {
    position: relative;
    margin-bottom: 2rem;

    &::before {
      content: '—';
      color: var(--post-accent-color);
      position: absolute;
    }

    &::before {
      top: -4rem;
    }

    h6 {
      padding-right: 1rem;
    }

    li {
      margin: 3rem 0;
      padding-right: 1rem;
      width: 15rem;

      p {
        font-size: 0.75rem;
        line-break: ;
      }
    }
  }
`;

const FooterContainer = styled.footer`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding-bottom: 4rem;
  background: ${(props) => props.theme.colors.black} !important;
  color: ${(props) => props.theme.colors.white} !important;

  @media ${(props) => props.theme.device.tablet} {
    padding-bottom: 6.25rem;
  }
`;
