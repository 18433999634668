import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion, useViewportScroll, AnimateSharedLayout } from 'framer-motion';
import { changeTheme, hoverChange } from '../../functions/util';
import Link from '../link';

/**
 * Svg
 */
import Logo from '../svg/logo';
import Toggle from '../svg/toggle';

/**
 * Data hooks
 */
import useNavData from '../../hooks/use-nav-data';
import { NavLinkItem } from './nav-link-item';

/**
 * Night and Day Button Component
 *
 */
function NightDayButton() {
  const [state, setState] = useState('dark');

  useEffect(() => {
    if (typeof document !== `undefined`) {
      setTimeout(() => {
        const inverted = document.body.getAttribute('data-inverted');
        if (inverted) {
          setState('light');
        }
      }, 1000);
    }
  }, []);

  function handleChange() {
    if (state === 'light') {
      setState('dark');
      changeTheme(null, null);
    } else {
      setState('light');
      changeTheme('#000000', '#ffffff');
    }
  }

  return (
    <Toggle
      className="toggle"
      onMouseEnter={() => hoverChange('link')}
      onMouseLeave={() => hoverChange(null)}
      onClick={() => handleChange()}
      data-state={state}
    />
  );
}

/**
 * Nav component
 *
 * @param {Object} props
 */
export default function Nav(props) {
  const navData = useNavData();
  const { scrollY } = useViewportScroll();
  const [hidden, setHidden] = React.useState(false);

  function update() {
    if (scrollY?.current < scrollY?.prev) {
      setHidden(false);
    } else if (scrollY?.current > 100 && scrollY?.current > scrollY?.prev) {
      setHidden(true);
    }
  }

  useEffect(() => {
    return scrollY.onChange(() => update());
  });

  const wrapperVariants = {
    pre: {
      opacity: 0,
      y: -10,
      transition: {
        delay: 0.3
      }
    },
    visible: { opacity: 1, y: 0 }
  };

  const subNavVariants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 0 }
  };

  const lineVariants = {
    pre: { opacity: 0, width: '0', y: -20 },
    visible: { opacity: 1, width: '100%', y: 0 },
    hidden: { opacity: 0, width: '0', y: -20 }
  };

  return (
    <>
      <NavWrapper
        initial="pre"
        animate="visible"
        variants={wrapperVariants}
        onHoverStart={() => setHidden(false)}
        transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.8 }}
      >
        <NavLink className="button" to="/">
          <Logo className="logo" aria-label={'Home Page'} />
        </NavLink>
        <Line
          onHoverStart={() => setHidden(false)}
          initial="pre"
          animate={hidden ? 'hidden' : 'visible'}
          variants={lineVariants}
          transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.8 }}
        />
        <NavLinksWrapper
          layout
          initial="pre"
          animate={hidden ? 'hidden' : 'visible'}
          onHoverStart={() => setHidden(false)}
          variants={subNavVariants}
          transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.8 }}
        >
          <AnimateSharedLayout>
            <NavLinkItem data={navData} />
            <NightDayButton />
          </AnimateSharedLayout>
        </NavLinksWrapper>
      </NavWrapper>
    </>
  );
}

const Line = styled(motion.span)`
  height: 0.5rem;
  width: 100%;
  position: relative;
  margin: 1rem 0 1rem 0;
  pointer-events: visible;
  visibility: hidden;
  z-index: 10;
  transform-origin: left center;

  @media ${(props) => props.theme.device.mobileLg} {
    margin: 1rem 0 1rem 1rem;
    visibility: visible;
  }

  @media ${(props) => props.theme.device.laptop} {
    margin: 1rem 0 1rem calc(5vw - 3.125rem);
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    background: white;
    z-index: 10;
  }
`;

const NavWrapper = styled(motion.nav)`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 1rem;
  width: calc(100vw - 2rem);
  left: 0;
  mix-blend-mode: difference;
  pointer-events: none;
  z-index: 10;

  @media ${(props) => props.theme.device.tablet} {
    margin: 2rem 3vw 0;
    width: 94vw;
    /* height: 8rem; */
  }

  svg {
    fill: ${(props) => props.theme.colors.white};
  }

  a {
    color: ${(props) => props.theme.colors.white};
  }
`;

const NavLinksWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: visible;

  @media ${(props) => props.theme.device.mobileLg} {
    margin-left: 2rem;
  }

  a.h5,
  a.h6 {
    margin: 0;
  }

  .toggle {
    margin-left: 1rem;
    transform: translateY(-1px);
    pointer-events: initial;

    @media ${(props) => props.theme.device.tablet} {
      margin-left: 2rem;
    }
  }
`;

export const NavLink = styled(Link)`
  pointer-events: visible;
  position: relative;

  .logo {
    transform: scale(0.64);

    @media ${(props) => props.theme.device.tablet} {
      transform: scale(0.64);
    }
  }
`;
