import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Link from '../link';

/**
 * NavLinkItem component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export function NavLinkItem({ data }) {
  return (
    <>
      {data.map(({ node: { data, url } }, i) => {
        return <LinkItem key={i} data={data} url={url} />;
      })}
    </>
  );
}

function LinkItem({ data, url }) {
  const ref = useRef(null);
  const [hovered, setHovered] = useState(false);

  const variants = {
    hidden: {
      opacity: 0,
      scale: 1.23
    },
    visible: {
      opacity: 1,
      scale: 1
    }
  };

  return (
    <NavLink
      to={url}
      role="button"
      aria-label={data.title}
      className="h6 button"
      hoverData="nav"
    >
      <motion.div
        ref={ref}
        className="button-inner"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        aria-hidden="true"
        layout
      >
        {data.title}
        {hovered && (
          <NavLinkBackground
            layoutId="nav"
            className="nav-link-background"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={variants}
            transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
          />
        )}
      </motion.div>
    </NavLink>
  );
}

export const NavLink = styled(Link)`
  pointer-events: visible;
  position: relative;

  @media (hover: hover) {
    &:hover,
    *:hover {
      text-decoration: none;
    }
  }

  .button-inner {
    padding: 0.5rem;

    @media ${(props) => props.theme.device.tablet} {
      padding: 1rem;
    }
  }
`;

const NavLinkBackground = styled(motion.span)`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  background: transparent;
  border: solid 1px white;
  z-index: -1;
  border-radius: 50%;
`;
