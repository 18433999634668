import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import LinkExternal from '../link-external';
import { motion, useMotionValue, useTransform } from 'framer-motion';

export function Button({ children, text, href, icon, ...rest }) {
  const ref = useRef(null);
  const [hovered, setHovered] = useState(false);

  const cursorX = useMotionValue(0);

  const variants = {
    hidden: { opacity: 1, scale: 1, transition: { duration: 0.2 } },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.2 } }
  };

  useEffect(() => {
    const thisRefContainer = ref?.current;

    const moveCursor = (e) => {
      const elWidth = ref?.current?.offsetWidth / 2;
      const x = e.layerX - elWidth;
      cursorX.set(x);
    };
    if (thisRefContainer) {
      thisRefContainer.addEventListener('mousemove', moveCursor);
      return () => {
        thisRefContainer.removeEventListener('mousemove', moveCursor);
      };
    }
  }, [cursorX, ref]);

  const xOffset = useTransform(cursorX, [-20, 20], [-5, 5]);

  return (
    <ButtonWrapper
      href={href}
      {...rest}
      className="button"
      hoverData="button"
      animate={hovered ? 'visible' : 'hidden'}
      variants={variants}
      style={{
        translateX: xOffset
      }}
    >
      <ButtonInner
        ref={ref}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <h5 className="sequel">
          {text || children}
          {icon && icon}
        </h5>
      </ButtonInner>
    </ButtonWrapper>
  );
}

const ButtonInner = styled(motion.div)`
  display: flex;
  padding: 2rem 2.5rem;
`;

const ButtonWrapper = styled(LinkExternal)`
  display: flex;
  color: #ffff00 !important;
  background: transparent;
  border: solid 1px white;
  border-radius: 10rem;
  transition: transform ${(props) => props.theme.animation.duration[300].css}
      ${(props) => props.theme.animation.timingFunction.css},
    opacity ${(props) => props.theme.animation.duration[100].css}
      ${(props) => props.theme.animation.timingFunction.css} !important;

  @media (hover: hover) {
    &:hover {
      color: black !important;
      background: #ffff00 !important;
      border: solid 1px black;

      svg,
      svg path {
        fill: black !important;
      }
    }

    &:hover,
    *:hover {
      text-decoration: none;
    }
  }

  & * {
    margin: 0;
    text-decoration: none;
  }

  svg {
    transform: translateX(0.75em);
  }
`;
