import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={10}
      viewBox="0 0 24 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 0c0 2.484 2.016 4.5 4.5 4.5v1a4.502 4.502 0 00-4.5 4.5h-1c0-1.86.924-3.504 2.338-4.5H0v-1h20.838A5.495 5.495 0 0118.5 0h1z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgComponent;
