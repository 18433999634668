module.exports = `

@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@(                          (&@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@%                                      .@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@&           @@@@@@@@@@@@@@@@@@@@@@@@@           @@@@@@@@@@@@@@@@
@@@@@@@@@@@@@,        ,@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@         &@@@@@@@@@@@@
@@@@@@@@@@(        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#        @@@@@@@@@@
@@@@@@@@*       @@@@@@@@@@@@@@@%                /&@@@@@@@@@@@@@@/       &@@@@@@@
@@@@@@&       @@@@@@@@@@@@                            %@@@@@@@@@@@&       @@@@@@
@@@@@       &@@@@@@@@@%         %@@@@@@@@@@@@@@%*         @@@@@@@@@@       .@@@@
@@@@       @@@@@@@@@        %@@@@@@@@@@@@@@@@@@@@@@@*       /@@@@@@@@%       @@@
@@#       @@@@@@@@       .@@@@@@@@@@@@@@@@@@@@@@@@@@@@@       *@@@@@@@@       @@
@@       @@@@@@@&       @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@        @@@@@@@*       @
@       (@@@@@@(       @@@@@@                      %@@@@@%       @@@@@@@       ,
(       @@@@@@@       @@@@@@@      @@@@@@@@@@@&     *@@@@@@@@@@@@@@@@@@@#
        @@@@@@,       @@@@@@@      @@@@@@@@@@&      (@@@@@@@@@@@@@@@@@@@@
        @@@@@@,      #@@@@@@@                     @@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@,      .@@@@@@@      @@@@@%      @@@@@@@@@@@@@@@@@@@@@@@@@@
(       @@@@@@@       @@@@@@@      @@@@@@@&      @@@@@@@@@%((((((%@@@@@@#
@       (@@@@@@#      ,@@@@@@      @@@@@@@@@@      (@@@@@@       @@@@@@@       ,
@@       @@@@@@@&       @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@&       @@@@@@@*       @
@@#       @@@@@@@@       #@@@@@@@@@@@@@@@@@@@@@@@@@@@@@       &@@@@@@@@       @@
@@@@       @@@@@@@@@       /@@@@@@@@@@@@@@@@@@@@@@@@*       /@@@@@@@@*       @@@
@@@@@       &@@@@@@@@@%         &@@@@@@@@@@@@@@@%        ,@@@@@@@@@@       .@@@@
@@@@@@&       @@@@@@@@@@@@                            %@@@@@@@@@@@        @@@@@@
@@@@@@@@*       @@@@@@@@@@@@@@@&/               %@@@@@@@@@@@@@@@        &@@@@@@@
@@@@@@@@@@@        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@.        @@@@@@@@@@
@@@@@@@@@@@@@,         %@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@.         &@@@@@@@@@@@@
@@@@@@@@@@@@@@@@&            @@@@@@@@@@@@@@@@@@@@@@*            @@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@%                                      .@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@(                         (@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

Welcome to chrisrushing.com

@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

Designed by Chris Rushing

@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

Developed by John Choura (https://john.design)
Built with React, Gatsby, Prismic, Framer Motion, and Styled Components

@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  `;
