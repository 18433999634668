import { useStaticQuery, graphql } from 'gatsby';

export const useFooterData = () => {
  const {
    prismicFooter: { data }
  } = useStaticQuery(
    graphql`
      query FooterQuery {
        prismicFooter {
          data {
            awards {
              text
              type
              spans {
                start
                end
                type
                data {
                  link_type
                  target
                  url
                }
              }
            }
            clients {
              text
              type
            }
            contact
            experience {
              text
              type
              spans {
                start
                end
                type
                data {
                  link_type
                  target
                  url
                }
              }
            }
            legal
            social_links {
              url
              svg {
                dimensions {
                  width
                  height
                }
                url
              }
            }
            ticker_text
            talks {
              text
              type
              spans {
                start
                end
                type
                data {
                  link_type
                  target
                  url
                }
              }
            }
          }
        }
      }
    `
  );
  return data;
};

export default useFooterData;
