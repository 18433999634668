import React from 'react';
import { hoverChange } from '../functions/util';
import { motion } from 'framer-motion';

function LinkExternal({ href, blank, children, hoverData, ...rest }) {
  return (
    <motion.a
      href={href}
      target={blank && '_blank'}
      rel={blank && 'nofollow'}
      onMouseEnter={() => hoverChange(hoverData ? hoverData : 'link')}
      onMouseLeave={() => hoverChange(null)}
      {...rest}
    >
      {children}
    </motion.a>
  );
}

export default LinkExternal;
