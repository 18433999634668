import styled from "styled-components";

export const PageWrapper = styled.div`
  margin: 0 auto;
  width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  overflow: hidden;

  &[data-page="Profile"] {
    min-height: 80vh;
  }
`;

export const Wrapper = styled.section`
  padding: 0 1rem;
  width: 100%;

  @media ${(props) => props.theme.device.tablet} {
    padding: 0 8vw;
  }

  &.pV {
    padding-top: 8vw;
    padding-bottom: 8vw;
  }

  &.pT {
    padding-top: 8vw;
  }

  &.pB {
    padding-bottom: 8vw;
  }
`;
