import { createGlobalStyle } from "styled-components";

export const BaseStyles = createGlobalStyle`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
      display: block;
  }
  body {
      padding: 0;
      margin: 0;
  }
  ol,
  ul {
      list-style: none;
  }
  blockquote,
  q {
      quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
      content: "";
      content: none;
  }
  table {
      border-collapse: collapse;
      border-spacing: 0;
  }

  /* html {
    scroll-behavior: smooth;
  } */

  html {
    background-color: ${(props) => props.theme.colors.black};
    font-size: 16px;
  }

  body, html {
    width: 100vw;
    overflow-x: hidden;
    min-height: 100vh;
  }

  body {
      font-family: serif;
      background-color: ${(props) => props.theme.colors.black};
      color: ${(props) => props.theme.colors.white};
  }

  * {
      box-sizing: border-box;
      transition-timing-function: ${(props) =>
        props.theme.animation.timingFunction.css};
  }

  @media (hover: hover) {
    * { cursor: none; }
  }

  body[data-hover=true] {
    .hover-image {
      visibility: visible;
      opacity: 1;
    }
  }

  body[data-iframe=true] {
    .hover-iframe {
      visibility: visible;
      opacity: 1;
    }
  }

  /* main#main {
    min-height: 90vh;
  } */

  a {
    text-decoration: none;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      transform: scaleX(0);
      transform-origin: center left;
      background: white;
      mix-blend-mode: difference;
      transition: transform ${(props) =>
        props.theme.animation.duration[200].css}
      ${(props) => props.theme.animation.timingFunction.css};
    }

    &.no-underline, &.button {
      &:after {
        opacity: 0;
      }
    }

    @media (hover: hover) {
      &:hover {
        text-decoration: none;

        &:after {
          transform: scaleX(1);
        }

        p {
          text-decoration: none;
        }

        * {
          text-decoration: none !important;
        }
      }
    }
  }

  *:focus-visible {
    outline: none;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: -3px;
      left: -3px;
      bottom: 0;
      right: 0;
      border: solid 3px white;
      border-radius: 4px;
      mix-blend-mode: difference;
    }
  }

  pre, code {
    font-family: "input-mono-condensed", monospace;
    font-weight: 400;
    margin: 1rem 0;
  }

  hr {
    border: solid 1.5px ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.white};
    margin: 3rem 0;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  p,
  .p {
    margin-top: 0;
    margin-bottom: 1rem;

    &-sequel,
    &.sequel,
    &[data-sequel] {
      font-family: "Sequel_100 Wide W05_95", sans-serif;
      text-transform: uppercase;
      font-style: normal;

    }
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4 {
    font-family: "ivypresto-display", serif;
    font-weight: 100;
    font-style: italic;
    letter-spacing: 0.01em;
    margin-left: -0.02em;
  }

  h1,
  .h1 {
    font-size: ${(props) => props.theme.fonts.h1};
    line-height: 108%;
  }

  h2,
  .h2 {
    font-size: ${(props) => props.theme.fonts.h2};
    line-height: 110%;
  }

  h3,
  .h3 {
    font-size: ${(props) => props.theme.fonts.h3};
    line-height: 115%;
  }

  h4,
  .h4 {
    font-size: ${(props) => props.theme.fonts.h4};
    line-height: 120%;
  }

  h5,
  .h5 {
    font-family: "input-mono-condensed", monospace;
    font-weight: 400;
    font-style: normal;
    font-size: ${(props) => props.theme.fonts.h5};
    line-height: 120%;
    letter-spacing: 0.105em;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss05' on, 'cpsp' on, 'calt' off,
      'liga' off;
  }

  h6,
  .h6 {
    font-family: "input-mono-condensed", monospace;
    font-weight: 400;
    font-style: normal;
    font-size: ${(props) => props.theme.fonts.h5};
    line-height: 120%;
    letter-spacing: 0.105em;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss05' on, 'cpsp' on, 'calt' off,
      'liga' off;
  }

  p,
  .p {
    font-family: interstate, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 200%;
    font-feature-settings: 'ss05' on, 'ss03' on, 'ss07' on, 'calt' off,
      'liga' off;

    &.strong {
      font-weight: 500;
    }
  }

  p.lead,
  .p-lead {
    font-size: 24px;
    line-height: 200%;
    font-feature-settings: 'ss05' on, 'ss03' on, 'ss07' on, 'calt' off,
      'liga' off;
  }

  p.caption,
  .p-caption {
    font-size: 0.65rem;
    font-family: "input-mono-condensed", monospace;
    font-weight: 400;
    font-style: normal;
    line-height: 120%;
    letter-spacing: 0.105em;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss05' on, 'cpsp' on, 'calt' off,
      'liga' off;


    @media ${(props) => props.theme.device.tablet} {
      font-size: 0.8rem;
    }
  }

  a {
    color: ${(props) => props.theme.colors.white};
    transition: opacity ${(props) => props.theme.animation.duration[100].css}
      ${(props) => props.theme.animation.timingFunction.css};
    opacity: 1;

    @media (hover: hover) {
      &:hover {
        opacity: .9;
      }
    }
  }

  .gatsby-resp-image-image {
    box-shadow: none !important;
  }

  .ReactModal__Overlay {
    opacity: 0;
    will-change: opacity;
    transition: opacity ${(props) => props.theme.animation.duration[100].css}
        ${(props) => props.theme.animation.timingFunction.css};

  }

  .ReactModal__Content {
    transition: transform ${(props) => props.theme.animation.duration[100].css}
        ${(props) => props.theme.animation.timingFunction.css};
    transform: translateY(50%);
    will-change: transform;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;

    .ReactModal__Content {
      transform: none;
    }
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;

    .ReactModal__Content {
      transform: translateY(50%);
    }
  }

  *[data-show="mobile"] {
    display: inherit;
    visibility: visible;

    @media ${(props) => props.theme.device.laptop} {
      display: none;
      visibility: hidden;
    }
  }

  *[data-show="laptop"] {
    display: none;
    visibility: hidden;

    @media ${(props) => props.theme.device.laptop} {
      display: block;
      visibility: visible;
    }
  }

  *[data-show="desktop"] {
    display: none;
    visibility: hidden;

    @media ${(props) => props.theme.device.desktop} {
      display: block;
      visibility: visible;
    }
  }

  body[data-dvd=true] {
    #cursor span {
      transform: scale(1) !important;
      background: red !important;
      opacity: 1 !important;
    }

    #cursor-lg span {
      width: 5rem;
      height: 2.7rem;
      background: transparent !important;
      border: none;
      border-radius: 0;
      opacity: 1;

      &:after {
        content: "";
        width: 5rem;
        height: 2.6875rem;
        background: url('/svg/dvd.svg') no-repeat;
        position: absolute;
        top: 100%;
        right: -40%;
        transform: translateY(-50%) rotate(0deg);
        text-transform: uppercase;
      }
    }

    #cursor-element span {
      transform: scale(1);
      opacity: 0;

    }
  }

  body[data-crushing=true] {
    #cursor span {
      transform: scale(1) !important;
      background: red !important;
      opacity: 1 !important;
    }

    #cursor-lg span {
      width: 5rem;
      height: 2.7rem;
      background: transparent !important;
      border: none;
      border-radius: 0;
      opacity: 1;

      &:after {
        content: "";
        width: 5rem;
        height: 5rem;
        background: url('/svg/face.svg') no-repeat;
        position: absolute;
        top: 144%;
        right: -40%;
        transform: translateY(-50%) rotate(0deg);
        text-transform: uppercase;
      }
    }

    #cursor-element span {
      transform: scale(1);
      opacity: 0;

    }
  }

  #cursor[data-reduce-motion=true] span {
    transform: scale(1.5) !important;
  }

  @media (hover: hover) {
    body[data-hover="link"] {

      #cursor span {
        transform: scale(0);
        background: transparent;
      }

      #cursor-lg span {
        transform: scale(1);
        opacity: 0;
      }

      #cursor-element span {
        transform: scale(1);
        background: white;
        opacity: 1;
      }
    }

    body[data-hover="nav"] {

      #cursor-lg span {
        transform: scale(1.4);
        opacity: 0;
      }

      #cursor-element span {
        transform: scale(1.4);
        background: transparent;
        opacity: 0;
      }
    }

    body[data-hover="button"] {

      #cursor-lg span {
        border-color: transparent;
        opacity: 0;
      }

      #cursor-element span {
        transform: scale(3);
        background: transparent;
        opacity: 0;
      }
    }

    body[data-hover="project"] {

      #cursor span {
        transform: scale(25);
        background: transparent !important;
      }

      #cursor-lg span {
        transform: scale(0);
        border-color: transparent;
        opacity: 0;
      }

      #cursor-element span {
        transform: scale(1);
        background: transparent;
        border-color: transparent;
        opacity: 1;

        &:after {
          content: "";
          width: 10rem;
          height: 10rem;
          background: url('/svg/hover-arrow.svg') no-repeat;
          background-size: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0) rotate(0deg);
          text-transform: uppercase;
        }
      }
    }

    body[data-hover="video"] {

      #cursor span {
        transform: scale(25);
        background: transparent !important;
      }

      #cursor-lg span {
        transform: scale(0);
        border-color: transparent;
        opacity: 0;
      }

      #cursor-element span {
        transform: scale(1);
        background: transparent;
        border-color: transparent;
        opacity: 1;

        &:after {
          content: "";
          width: 10rem;
          height: 10rem;
          background: url('/svg/play.svg') no-repeat;
          background-size: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0) rotate(0deg);
          text-transform: uppercase;
        }
      }
    }

    body[data-hover="john"] {

      #cursor span {
        transform: scale(0);
        background: transparent;
      }

      #cursor-lg span {
        transform: scale(3);
        opacity: 0;
      }

      #cursor-element span {
        transform: scale(3);
        background: white;
        opacity: 1;

        &:after {
          content: "✌️";
          position: absolute;
          width: 100%;
          height: auto;
          font-family: 'Sequel_100 Wide W05_95', sans-serif;
          font-size: .5rem;
          text-align: center;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0) rotate(0deg);
          text-transform: uppercase;
        }
      }
    }
  }
`;
