import React, { useEffect } from "react";

/**
 * Local Components
 */
import Head from "./globals/head";
import Nav from "./globals/nav";
import Footer from "./globals/footer";

/**
 * Local styles
 */
import { PageWrapper } from "./style/global-styles";
import "./style/fonts.css";
import Cursor from "./cursor";
import { dvd, crushing, hoverChange } from "../functions/util";
import ascii from "../data/ascii";

/**
 * Code Highlighting styles
 */

/**
 * Layout Component
 *
 * @param {Object} props
 * @param {any} props.children
 * @param {Object} props.pageContext
 * @returns page layouts
 */
export default function Layout({ children, pageContext }) {
  useEffect(() => {
    console.log(ascii);
  }, []);

  useEffect(() => {
    return () => {
      hoverChange(null);
    };
  });

  useEffect(() => {
    let log = "";

    if (typeof document !== `undefined`) {
      const update = (e) => {
        if (e.key === "Escape") {
          log = "";
          dvd(false);
          crushing(false);
        } else {
          log += `${e.key}`;
          if (log === "dvd") {
            dvd(true);
            log = "";
          } else if (log === "crushing") {
            crushing(true);
            log = "";
          }
        }
      };

      document.addEventListener("keydown", (e) => update(e));

      return () => {
        document.removeEventListener("keydown", (e) => update(e));
      };
    }
  });

  return (
    <>
      <Head pageContext={pageContext} />
      <Nav pageContext={pageContext} />
      <PageWrapper data-page={pageContext.title}>
        <main id="main">{children}</main>
      </PageWrapper>
      <Footer pageContext={pageContext} />
      <Cursor />
    </>
  );
}
