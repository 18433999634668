import { createGlobalStyle } from 'styled-components';
import { animation } from '../../data/baseTheme';

/**
 * AnimationStyles Component
 * Exports all rendered styles
 */
export const AnimationStyles = createGlobalStyle`
	body {
			transition:
		background-color ${animation.duration[200].css} ${animation.timingFunction.css},
			color ${animation.duration[200].css} ${animation.timingFunction.css};
		will-change: background-color, color;

		h1, h2, h3, h4, h5, h6, p, a, .nav > a {
			transition:
				color ${animation.duration[200].css} ${animation.timingFunction.css};
			will-change: color;
		}

		svg {
			transition:
				fill ${animation.duration[200].css} ${animation.timingFunction.css};
			will-change: fill;
		}

		section {
			transition:
				background-color ${animation.duration[200].css} ${animation.timingFunction.css};
			will-change: background-color;
		}

		nav .toggle circle {
			/* mix-blend-mode: none !important; */
			stroke: white !important;
			fill: white !important;
		}

		.inverted-svg {
			display: none
		}
	}

	body[data-inverted=false] {
			.uninverted-svg {
				display: inherit
			}
			.inverted-svg {
				display: none
			}
		}

	body[data-inverted=true] {
		.uninverted-svg {
			display: none
		}
		.inverted-svg {
			display: inherit
		}
	}

  body.theme {
    background-color: var(--theme-background) !important;
    color: var(--theme-foreground) !important;
		transition:
			background-color ${animation.duration[200].css} ${animation.timingFunction.css},
			color ${animation.duration[200].css} ${animation.timingFunction.css};

    h1, h2, h3, h4, h5, h6, p, a {
			color: var(--theme-foreground) !important;
			text-decoration-color: var(--theme-foreground) !important;
			transition:
				color ${animation.duration[200].css} ${animation.timingFunction.css},
				text-decoration-color ${animation.duration[200].css} ${animation.timingFunction.css};
    }

		nav, nav a, nav svg {
			/* mix-blend-mode: none !important; */
			color: var(--theme-background) !important;
			fill: var(--theme-background) !important;
		}

		nav .toggle circle {
			/* mix-blend-mode: none !important; */
			stroke: var(--theme-background) !important;
			fill: var(--theme-background) !important;
		}

    svg {
			fill: var(--theme-foreground);
			transition: fill ${animation.duration[200].css} ${animation.timingFunction.css}
    }

		.ticker {
			&::before,
			&::after {
				background: var(--theme-foreground);
			}
		}

		.ticker a {
			h1 {
				color: var(--theme-foreground) !important;

				&.sequel {
					-webkit-text-stroke-color: var(--theme-foreground) !important;
					color: transparent !important;
				}
			}

			@media (hover: hover) {
				&:hover {
					h1 {
						-webkit-text-stroke-color: var(--theme-foreground) !important;
						color: transparent !important;

						&.sequel {
							-webkit-text-stroke-color: transparent;
							color: var(--theme-foreground) !important;
						}
					}
				}
			}
		}

		*[data-inverse-reverse=true] {
			background-color: var(--theme-foreground) !important;
			color: var(--theme-background) !important;

			h1, h2, h3, p, a {
				color: var(--theme-background) !important;
			}
		}

		.button {
			border-color: var(--theme-foreground) !important;

			svg path {
				/* fill:  var(--theme-foreground) !important; */
			}
		}

		footer {
			background: var(--theme-background) !important;

			&, & > div {
				background: var(--theme-background) !important;
			}

			h1, h2, h3, h4, h5, h6, p, a, .nav > a, svg path {
				color: var(--theme-foreground) !important;
				fill: var(--theme-foreground) !important;
			}

			ul:before {
				color: var(--theme-foreground) !important;
			}
		}
  }

  body {
    transition:
      background-color ${animation.duration[200].css} ${animation.timingFunction.css},
      color ${animation.duration[200].css} ${animation.timingFunction.css};
  }

  section, .section {
    transition:
      background-color ${animation.duration[200].css} ${animation.timingFunction.css};
  }
`;
