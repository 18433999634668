import { useStaticQuery, graphql } from "gatsby";

export const useMeta = () => {
  const {
    prismicMeta: { data },
  } = useStaticQuery(
    graphql`
      query meta {
        prismicMeta {
          id
          data {
            site_description
            site_title
            site_url
            site_image {
              url
            }
          }
        }
      }
    `
  );

  return data;
};

export default useMeta;
