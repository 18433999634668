import { useStaticQuery, graphql } from 'gatsby';

export const useNavData = () => {
  const data = useStaticQuery(
    graphql`
      query navDataQuery {
        allPrismicPage(
          filter: {
            data: { hide_in_nav: { eq: false }, top_level_page: { eq: true } }
          }
          sort: { order: ASC, fields: data___nav_order }
        ) {
          edges {
            node {
              id
              uid
              url
              data {
                title
                nav_order
              }
            }
          }
        }
        allPrismicProfilePage {
          edges {
            node {
              id
              uid
              url
              data {
                title
                nav_order
              }
            }
          }
        }
        allPrismicPlaygroundPage {
          edges {
            node {
              id
              uid
              url
              data {
                title
                nav_order
              }
            }
          }
        }
      }
    `
  );

  const groupEdges = [
    ...data.allPrismicPage.edges,
    ...data.allPrismicProfilePage.edges,
    ...data.allPrismicPlaygroundPage.edges
  ];

  return groupEdges;
};

export default useNavData;
