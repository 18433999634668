import React, { useEffect } from "react";
import {
  motion,
  useMotionValue,
  useReducedMotion,
  useSpring,
} from "framer-motion";
import styled from "styled-components";

export function CursorShape({ children, ...rest }) {
  return (
    <motion.div initial={false} {...rest}>
      {children}
    </motion.div>
  );
}

export default function Cursor() {
  const cursorX = useMotionValue(1000);
  const cursorY = useMotionValue(1000);
  const reduceMotion = useReducedMotion();

  useEffect(() => {
    if (typeof document !== `undefined` && typeof window !== `undefined`) {
      const moveCursor = (e) => {
        const elWidth = 32;
        const elHeight = 32;

        cursorX.set(e.clientX - elWidth / 2);
        cursorY.set(e.clientY - elHeight / 2);
      };
      window.addEventListener("mousemove", moveCursor);
      return () => {
        window.removeEventListener("mousemove", moveCursor);
      };
    }
  }, [cursorX, cursorY]);

  const springConfig = { stiffness: 100, damping: 12, mass: 0.3 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  return (
    <>
      <CursorWrap
        id="cursor"
        data-reduce-motion={reduceMotion}
        reduceMotion={reduceMotion}
        style={{
          translateX: cursorX,
          translateY: cursorY,
        }}
      >
        <CursorSmElement />
      </CursorWrap>
      <CursorWrap
        id="cursor-element"
        data-reduce-motion={reduceMotion}
        reduceMotion={reduceMotion}
        style={{
          translateX: cursorX,
          translateY: cursorY,
        }}
      >
        <CursorElement reduceMotion={reduceMotion} />
      </CursorWrap>
      <CursorWrap
        id="cursor-lg"
        data-reduce-motion={reduceMotion}
        reduceMotion={reduceMotion}
        style={{
          translateX: reduceMotion ? cursorX : cursorXSpring,
          translateY: reduceMotion ? cursorY : cursorYSpring,
        }}
      >
        <CursorLgElement />
      </CursorWrap>
    </>
  );
}

const CursorWrap = styled(CursorShape)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  mix-blend-mode: difference;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 9999;

  &#cursor-element span {
    &:after {
      content: "";
      will-change: transform;
      top: 50%;
      left: 50%;
      transform: ${({ reduceMotion }) =>
        reduceMotion
          ? "translate3d(-50%, -50%, 0) rotate(0deg)"
          : "translate3d(-50%, -50%, 0) rotate(-100deg)"};
      transition: transform
        ${(props) => props.theme.animation.duration[100].css}
        ${(props) => props.theme.animation.timingFunction.css};
    }
  }

  @media (hover: none) {
    visibility: hidden;
    display: none;
  }
`;

const CursorSmElement = styled.span`
  width: 0.5rem;
  height: 0.5rem;
  background: white;
  mix-blend-mode: difference;
  margin-left: 12px;
  margin-top: 12px;
  border-radius: 1rem;
  transform: scale(1.5);
  z-index: 9999;
  pointer-events: none;
  transition: transform ${(props) => props.theme.animation.duration[300].css}
      ${(props) => props.theme.animation.timingFunction.css},
    opacity ${(props) => props.theme.animation.duration[100].css}
      ${(props) => props.theme.animation.timingFunction.css},
    background ${(props) => props.theme.animation.duration[100].css}
      ${(props) => props.theme.animation.timingFunction.css} !important;
`;

const CursorElement = styled.span`
  width: 2rem;
  height: 2rem;
  background: transparent;
  mix-blend-mode: difference;
  border: solid 0.2px white;
  transform: ${({ reduceMotion }) =>
    reduceMotion ? "scale(1)" : "scale(0.5)"};
  opacity: 0;
  left: 0;
  top: 0;
  border-radius: 1rem;
  z-index: 9999;
  pointer-events: none;
  transition: transform ${(props) => props.theme.animation.duration[300].css}
      ${(props) => props.theme.animation.timingFunction.css},
    opacity ${(props) => props.theme.animation.duration[100].css}
      ${(props) => props.theme.animation.timingFunction.css},
    background ${(props) => props.theme.animation.duration[100].css}
      ${(props) => props.theme.animation.timingFunction.css} !important;
`;

const CursorLgElement = styled.span`
  width: 2rem;
  height: 2rem;
  background: transparent;
  mix-blend-mode: difference;
  border: solid 0.2px white;
  transform: scale(2);
  opacity: 0.33;
  left: 0;
  top: 0;
  border-radius: 1rem;
  z-index: 9999;
  pointer-events: none;
  transition: transform ${(props) => props.theme.animation.duration[300].css}
      ${(props) => props.theme.animation.timingFunction.css},
    opacity ${(props) => props.theme.animation.duration[100].css}
      ${(props) => props.theme.animation.timingFunction.css},
    background ${(props) => props.theme.animation.duration[100].css}
      ${(props) => props.theme.animation.timingFunction.css} !important;
`;
