import React from "react";
import Helmet from "react-helmet";
import useMeta from "../../hooks/use-meta";

/**
 * TODO: need an OG Image and description
 * */
export const meta = {
  siteUrl: "https://chrisrushing.com",
  description:
    "Chris Rushing is an award winning creative director and designer.",
  og: "/images/og.png",
};

/**
 * Head component
 *
 * @param {Object} props
 */
function Head({ pageContext }) {
  const { site_description, site_title, site_url, site_image } = useMeta();

  const title = `${site_title} ${
    pageContext.title ? `— ${pageContext.title}` : ""
  }`;

  return (
    <Helmet
      title={title}
      meta={[
        // Basics
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1, shrink-to-fit=yes",
        },
        { name: "title", content: title },
        { name: "description", content: site_description },
        // Open Graph / Facebook
        { property: "og:type", content: "website" },
        { property: "og:url", content: site_url },
        { property: "og:title", content: title },
        { property: "og:description", content: site_description },
        { property: "og:image", content: `${site_image.url}` },
        // Twitter
        { property: "twitter:card", content: "summary_large_image" },
        { property: "twitter:url", content: site_url },
        { property: "twitter:title", content: title },
        { property: "twitter:description", content: site_description },
        { property: "twitter:image", content: `${site_image.url}` },
      ]}
    />
  );
}

export default Head;
